@import './_variables.scss';

/* Sidebar Styles */
#sidebar-wrapper {
  width: 15%;
  position: fixed;
  height: 100vh;
  box-shadow: 0px 0px 30px #9299b81a;
  padding: 15px;
  background-color: white;

  .main-menu-text {
    color: #9299b8;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 5px;
    padding-left: 15px;
  }

  .sidebar-nav {
    top: 0;
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      text-indent: 20px;
      line-height: 50px;
      margin-bottom: 5px;
      a {
        display: flex;
        text-decoration: none;
        color: #272b41;
        padding: 0 15px;
        font-size: 14px;
        &:hover {
          text-decoration: none;
          background-color: rgba(24, 54, 105, 0.05);
          border-radius: 4px;
        }

        span {
          margin-left: -10px;
        }

        &.btn-success {
          color: white; 
          &:hover {
            background-color: #218838;
            border-color: #1e7e34;
          }
        }
      }
    }
    li.sidebar-brand {
      a {
        &:hover {
          background-color: transparent;
        }

        img {
          display: block;
          width: 100%;
          height: 58px;
          object-fit: cover;
        }
      }
    }
    .logout {
      cursor: pointer;
    }
  }
}

.clicked a {
  background-color: rgba(24, 54, 105, 0.05);
  border-radius: 4px;
  color: $blue !important;
}
