@import '../../../assets/style/variables';

input:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  .toggle-switch {
    .switch {
      position: relative;
      display: inline-block;
      width: 40px;
      height: 25px;
      input {
        opacity: 0;
        width: 0;
        height: 0;
      }
    }
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      &:before {
        position: absolute;
        content: '';
        height: 17px;
        width: 17px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
    }
    input {
      &:checked {
        + {
          .slider {
            background-color: #2196f3;
            &:before {
              -webkit-transform: translateX(16px);
              -ms-transform: translateX(16px);
              transform: translateX(16px);
            }
          }
        }
      }
      &:focus {
        + {
          .slider {
            box-shadow: 0 0 1px #2196f3;
          }
        }
      }
    }
    .slider.round {
      border-radius: 34px;
      &:before {
        border-radius: 50%;
      }
    }
  }
  
  .customers {
    header {
      min-height: 0px;
    }
  
    .rdt_TableHeader {
      display: none;
    }
  }