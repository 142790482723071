@import './features.scss';
@import './plans.scss';
@import './schedule.scss';
@import './aboutus.scss';
@import './contactus.scss';

.landing_page {
  position: relative;

  .header-nav {
    box-shadow: none;
    background-color: transparent;
  }

  .scroll {
    box-shadow: 0px 3px 6px #00000029;
    background-color: #ffffff;
  }

  .landing_top {
    font-family: 'Helvetica Neue-Medium';
    padding: 3% 5%;

    img {
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
    }

    .text {
      padding: 20px 30px 20px 0px;

      h1 {
        font-size: 72px;
        line-height: 79px;
      }

      h5 {
        font-size: 26px;
        line-height: 32px;
        color: #8b8b8b;

        span {
          color: #f5333f;
        }
      }

      .trial_blc {
        .trial {
          background-color: #1bce82;
          border-radius: 23px;
          font-size: 22px;
          line-height: 30px;
          color: #ffffff;
          padding: 10px 36px;
        }
        p {
          padding: 5px 35px;
          font-size: 18px;
          line-height: 20px;
          color: #535353;
        }
      }
    }
  }
  .landing_features {
    padding: 0 5%;

    p {
      font-family: 'Helvetica Neue-Light';
      margin: 0;
      font-size: 15px;
      line-height: 20px;
      color: #646464;
      strong {
        color: #000000;
      }
    }

    span,
    h5 {
      font-size: 17px;
      line-height: 20px;
    }
    .features_card {
      width: 80%;
      box-shadow: 0px 6px 9px #00000029;
      border: 1px solid #f0f0f0;

      h2 {
        font-size: 39px;
        line-height: 46px;
      }

      .card_item {
        padding: 10px;

        img {
          width: 30px;
          padding: 2px 5px;
          margin-right: 10px;
        }

      }
    }
    .features_summary {
      h1 {
        font-size: 46px;
        line-height: 50px;
      }
    }
  }
  .landing_how {
    background-color: #329bdd;

    h1 {
      font-size: 52px;
      line-height: 62px;
      span {
        color: #f5333f;
      }
    }

    h2 {
      font-family: 'Helvetica Neue-Light';
      font-size: 46px;
      line-height: 55px;
    }

    h3 {
      font-family: 'Helvetica Neue-Light';
      font-size: 41px;
      line-height: 50px;

      span {
        color: #f5333f;
      }
    }

    h5 {
      font-size: 19px;
      line-height: 23px;
      margin: 0;
    }

    p {
      font-size: 17px;
      line-height: 20px;
    }

    .manage {
      padding: 2% 0 5% 5%;
      overflow-x: hidden;

      .dashboard_img {
        position: absolute;
        left: 40%;
      }
    }

    .nexus {
      padding: 2% 10%;
    }
  }

  .landing_signup {
    padding: 0 5%;

    .signup {
      font-family: 'Helvetica Neue-Medium';
      background-color: #1b6cce;
      border-radius: 23px;
      font-size: 20px;
      line-height: 25px;
      color: #ffffff;
      padding: 10px 36px;

      &:hover {
        color: #ffffff;
      }
    }
  }

  .landing_money {
    padding: 2% 10%;
    background-color: #f7f7f7;

    h4 {
      font-size: 28px;
      line-height: 34px;
      margin: 0;
    }

    h5 {
      font-family: 'Helvetica Neue-Light';
      font-size: 19px;
      line-height: 23px;
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}

@media only screen and (min-width: 1366px) {
  
}
