body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-family: 'Helvetica Neue-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.center {
  text-align: center;
}


.column-2-space {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
span {
  &.button {
    &.confirm {
      width: 125px;
      cursor: pointer;
      margin: 0 15px;
    }
  }
  &.closebutton {
    cursor: pointer;
    font-weight: 600;
  }
}

.hidden {
  display: none;
}

.upload_button {
  display: inline;
  margin-left: 1em;
}

.upload_file_name,
.upload_file_name:disabled {
  display: inline;
}

.toast {
  &.toast-success {
    background-color: #51a351 !important;
  }
  &.toast-error {
    background-color: red !important;
  }
}
