@import '../../../assets/style/variables';

.paddingBottom {
    padding-bottom: 80px;
  }
  
  .viewPdfButton {
    color: #ffffff !important;
    background-color: $blue;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    font-weight: 400;
    margin-left: 5px;
    padding-top: 4px;
    padding-bottom: 7px;
    &:hover {
      color: white !important;
    }
  }
  
  .buttonLink {
    color: white;
    &:hover {
      text-decoration: none;
      color: white;
    }
  }
  
  .certificates .sc-fzqBZW.dNoAIX {
    margin-top: 80px;
    height: 30px;
  }
  
  .react-datepicker-wrapper {
    width: 100%;
  }
  
  .certificates {
    .datatable-title {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  
    .rdt_TableHeader {
      display: none;
    }
  }
  .multi-select {
    input:focus {
      box-shadow: none;
    }
  }
  .location-marker {
    display: flex;
    align-items: center;
    color: #055a8c;
    padding: 2px;
    // border: 1px solid #055a8c;
    // border-radius: 7px;
    max-width: 100%;
    cursor: pointer;
    i {
      font-size: 20px;
      padding: 4px 5px 4px 0px;
    }
    .state-name{
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  