.footer-new {
  padding: 2% 10%;
  background-color: #fdfdfd;
  box-shadow: 0px -3px 6px #00000029;

  a {
    color: gray;
    font-size: 16px;
    line-height: 23px;

    &:hover {
      color: #0c2e6d;
    }
  }
  p {
    font-size: 14px;
    line-height: 16px;
    color: #0c2e6d;
    margin: 2px;
  }
}
