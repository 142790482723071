.contactus_page {
  
  .contact_info {
    padding: 10%;
    height: 80vh;
    h1 {
      font-family: 'Helvetica Neue-Medium';
      font-size: 32px;
      line-height: 39px;
      color: #0c2e6d;
    }

    p {
      font-size: 17px;
      line-height: 20px;
      margin-bottom: 10px;

      span {
        color: #f5333f;
      }
    }

    .contacts {
      img {
        padding: 20px;
        text-align: center;
      }
    }
  }
  .contact_form {
    position: absolute;
    top: 15%;
    box-shadow: 0px 10px 20px #00000029;
    border: 1px solid #ebebeb;
    padding: 8%;
    background-color: #ffffff;
    width: 60%;
    z-index: 1;

    h2 {
      font-size: 32px;
      line-height: 39px;
      color: #f5333f;
      margin-bottom: 30px;
    }

    .form-control {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      box-shadow: none;
      background: transparent;
      border: 1px solid #f5333f;
      height: 54px;
      font-size: 18px;
      font-weight: 300;
    }

    #message {
      height: 130px;
    }
    .btn.btn-md {
      padding: 10px 25px;
    }
    .btn-submit {
      background: #f5333f;
      color: #fff;
      border: 2px solid #f5333f;
      border-radius: 0px;
      font-size: 20px;
      line-height: 25px;
    }
  }
}
