@font-face {
  font-family: 'Helvetica Neue-Regular';
  src: local('Helvetica Neue-Regular'), url(../fonts/HelveticaNeue-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Helvetica Neue-Light';
  src: local('Helvetica Neue-Light'), url(../fonts/HelveticaNeue-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Helvetica Neue-Medium';
  src: local('Helvetica Neue-Medium'), url(../fonts/HelveticaNeue-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Helvetica Neue-Bold';
  src: local('Helvetica Neue-Bold'), url(../fonts/HelveticaNeue-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Helvetica Neue-Italic';
  src: local('Helvetica Neue-Italic'), url(../fonts/HelveticaNeue-Italic.ttf) format('truetype');
}