.header-nav {
  padding: 2% 5%;
  box-shadow: 0px 3px 6px #00000029;
  background-color: #ffffff;

  img {
    height: 58px;
    object-fit: cover;
  }
  .nav-link {
    color: #909090;
    font-size: 16px;
    line-height: 18px;
    margin: 0 12px;
  }
}

.login_button {
  font-family: 'Helvetica Neue-Medium';
  background-color: #1b6cce;
  border-radius: 23px;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
  padding: 10px 36px;
  &:hover {
    color: #ffffff;
  }
}
