@import '../../../assets/style/variables';

.graph-data {
  &:hover {
    font-weight: 500;
  }
  cursor: pointer;
}

.graph-container {
  display: flex;
  width: 300px;
  margin: 0 0 0 20px;
  justify-content: space-evenly;
}

.col-activity-wrap > div:first-child {
  border-right: 1px solid #a0a0a0;
  padding-right: 0;
}

.col-activity-wrap > div:last-child {
  padding-left: 0;
  border-bottom: 1px solid #a0a0a0;
}

.dashboard .item {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
  padding-top: 10px;
}

.header .activity-wrap {
  padding: 5px 0px 5px 15px;
  border-bottom: 1px solid #a0a0a0;
  border-top: 1px solid #a0a0a0;
}

.col-activity-wrap .item {
  border-bottom: 1px solid #a0a0a0;
}

// New Dashboard Style
.row {
  margin-bottom: 15px;
}

.dashboard-title {
  font-size: 20px;
  color: #272b41;
}

.read-message {
  padding: 25px;
  background-color: #20c997;
  box-shadow: 0px 10px 20px #20c9970d;
  border-radius: 10px;
  margin-bottom: 15px;

  .read-message-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    h3 {
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
      margin: 0;
    }

    span {
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .progress {
    height: 5px;
    background-color: rgba($color: #fff, $alpha: 0.2);

    .progress-bar {
      height: 5px;
      background-color: white !important;
      border-radius: 4px;
    }
  }
}

.dashboard-summary {
  box-shadow: 0px 5px 20px #9299b808;
  border-radius: 10px;
  background-color: white;
  padding: 25px;

  .summary-title {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #f1f2f6;

    h1 {
      color: #272b41;
      text-transform: capitalize;
      font-size: 16px;
      margin: 0;
    }
  }
  .summary-item {
    display: flex;
    margin-bottom: 10px;
    cursor: pointer;

    &:last-child {
      margin: 0;
    }

    .main-logo {
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      margin-right: 20px;

      img {
        height: 25px;
        width: 25px;
      }

      &.first-item {
        background-color: rgba($color: #5f63f2, $alpha: 0.1);
      }

      &.second-item {
        background-color: rgba($color: #ff69a5, $alpha: 0.1);
      }

      &.third-item {
        background-color: rgba($color: #20c997, $alpha: 0.1);
      }      
      &.forth-item {
        background-color: rgba($color: #7eb120, $alpha: 0.1);
      }
      &.last-item {
        background-color: rgba($color: #fa8b0c, $alpha: 0.1);
      }
    }

    .text {
      p {
        margin: 0;
      }

      p:first-child {
        color: #272b41;
        font-size: 20px;
      }

      p:last-child {
        color: #9299b8;
        font-size: 14px;
      }
    }
  }
}

.expire-card {
  background-color: white;
  box-shadow: 0px 5px 20px #9299b808;
  border-radius: 10px;
  padding-left: 15px;

  .expire-card-header {
    padding: 20px 0;
    border-bottom: 1px solid #f1f2f6;

    h1 {
      color: #272b41;
      text-transform: capitalize;
      font-size: 16px;
      margin: 0;
    }
  }

  .expire-list {
    padding: 15px 15px 15px 0;

    .expire-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;

      .location-marker {
        color: gray;
        font-size: 12px;
        i {
          font-size: 12px;
        }
      }
      
      .main-part {
        display: flex;
        align-items: center;

        .date {
          width: 80px;
          padding: 20px 10px;
          background-color: #f8f9fb;
          border-radius: 10px;
          text-align: center;
          margin-right: 15px;

          p {
            margin: 0;
          }

          p:first-child {
            color: #272b41;
            font-size: 18px;
          }

          p:nth-child(2) {
            color: #5a5f7d;
            font-size: 13px;
          }
        }

        .file-content {
          p {
            margin: 0;
          }

          p:first-child {
            color: #272b41;
            font-size: 14px;
          }

          P:nth-child(2) {
            color: #9299b8;
            font-size: 12px;
          }

          a {
            font-size: 12px;
            color: #5f63f2;
          }
        }
      }
      svg {
        cursor: pointer;

        path {
          fill: #adb4d2;
        }
      }
    }
  }
}

.client-list {
  box-shadow: 0px 5px 20px #9299b808;
  border-radius: 10px;
  background-color: white;

  .client-list-header {
    padding: 13px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f1f2f6;

    h6 {
      color: #272b41;
      font-size: 16px;
      margin: 0;
    }

    button {
      border: 1px solid #f1f2f6;
      border-radius: 6px;
      padding: 6px 15px;
      background-color: white;
      color: #5f63f2;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        margin-right: 6px;
      }
    }
  }

  .client-list-content {
    padding: 30px 25px;

    .client-item {
      margin-bottom: 25px;

      &:last-child {
        margin: 0;
      }

      p {
        margin: 0 0 5px 0;
        font-size: 14px;
      }

      p:first-child {
        color: #272b41;
      }

      p:last-child {
        color: #9299b8;
      }
    }
  }
}

.activity-list {
  box-shadow: 0px 5px 20px #9299b808;
  background: white;
  border-radius: 10px;

  .nav-tabs {
    border-bottom: 1px solid #f1f2f6;

    a {
      border: none;
      margin-bottom: 0;
      padding: 18px 25px;
      font-size: 14px;
      color: #9299b8;

      &.active {
        color: #5f63f2;
        border-bottom: 1px solid;
      }
    }
  }

  .tab-content {
    padding: 25px 10px;
  }

  .activity-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 15px 50px #9299b833;
    background-color: white;
    padding: 11px 15px;

    div {
      flex: 1;
    }

    .left-side {
      display: flex;
      align-items: center;

      input {
        margin-right: 10px;
      }

      span {
        color: #5a5f7d;
        font-size: 14px;
      }
    }

    .right-side {
      display: flex;
      justify-content: flex-end;

      img {
        margin: 0 20px;
        width: 30px;
        height: 30px;
      }

      span {
        color: #9299b8;
        font-size: 12px;
      }
    }
  }
}

.context-menu {
  width: 100%;

  .context-menu-item {
    font-size: 14px;
    text-align: center;
    padding: 5px 10px;
    color: $blue;

    &:hover {
      background-color: $blue;
      color: #fff;
      cursor: pointer;
    }
  }
}
