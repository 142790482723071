.aboutus_page {

  h1 {
    font-size: 47px;
    line-height: 56px;
    color: #0c2e6d;
  }

  p {
    font-size: 16px;
    line-height: 18px;

    .dot {
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 2px;
      border-radius: 50%;
      border-style: solid;
      border-width: 1px;
      border-color: black;
      background-color: black;
      height: 7px;
      width: 7px;
    }
  }

  label {
    font-family: 'Helvetica Neue-Medium';
    font-size: 18px;
    line-height: 23px;
    color: #0c2e6d;
  }
}
