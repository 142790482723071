
.paddingBottom {
    padding-bottom: 70px
}


.activity-wrap > div:first-child {
    border-right: 1px solid #a0a0a0;
    padding-right: 0;
}

.activity-wrap > div:last-child {
    padding-left: 0;
    border-bottom: 1px solid #a0a0a0;
}

.dashboard .item {
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    padding-top: 10px;
}

.header {
    padding: 5px 0px 5px 15px;
    border-bottom: 1px solid #a0a0a0;
    border-top: 1px solid #a0a0a0;
}

.activity-wrap .item {
    border-bottom: 1px solid #a0a0a0;
}

.graph-data {
    &:hover { 
        font-weight: 500;
    }
    cursor: pointer;
}

.graph-container {
    display: flex;
    width: 300px;
    margin: 0 0 0 20px;
    justify-content: space-evenly;
}

.buttonLink {
    color: white;
    &:hover {
        text-decoration: none;
        color: white;
    }
}