.upload_success_page {
  h1 {
    font-size: 42px;
    line-height: 51px;
    color: #3587f9;
  }

  h3 {
    font-size: 30px;
    line-height: 36px;

    span {
      color: #3587f9;
    }
  }

  .buttons {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .btn-signup {
    background-color: #307df6;
    font-family: 'Helvetica Neue-Medium';
    color: #ffffff;
    font-size: 26px;
    line-height: 32px;
    padding: 15px 20px;
    margin: 2px;
  }

  .btn-upload {
    background-color: #06ad2a;
    font-family: 'Helvetica Neue-Medium';
    color: #ffffff;
    font-size: 26px;
    line-height: 32px;
    padding: 15px 20px;
    margin: 2px;
  }
}
