.feactures_page {
  .row {
    img {
      border-radius: 5px;
      box-shadow: 0px 10px 20px #00000029;
    }
  }

  h1 {
    margin: 5%;
    font-size: 55px;
    line-height: 66px;
  }

  .features_summary {
    width: 55%;

    p {
      font-size: 13px;
      line-height: 15px;

      strong {
        font-size: 15px;
        line-height: 18px;
      }
    }
  }
  .features_demo {
    margin: 40px;

    h4 {
      font-size: 25px;
      line-height: 30px;
      color: #1d9add;
    }

    .trial {
      font-family: 'Helvetica Neue-Medium';
      background-color: #f5333f;
      color: #ffffff;
      border-radius: 23px;
      padding: 10px 15px;
      margin: 20px;
      text-decoration: none;
    }

    a {
      text-decoration: underline;
    }
  }
}
