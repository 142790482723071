.schedule_page {
  
  h3 {
    font-size: 23px;
    line-height: 28px;
  }

  .img_blc {
    background: url('../../img/landing/schedule_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .schedule_title {
      padding: 10%;

      h1 {
        font-family: 'Helvetica Neue-Medium';
        font-size: 49px;
        line-height: 60px;
        color: #ffffff;
      }

      h2 {
        font-family: 'Helvetica Neue-Medium';
        color: #ffffff;
      }

      h5 {
        font-size: 20px;
        line-height: 28px;
        color: #ffffff;
        margin: 2% 0 5% 0;
      }

      .schedule {
        margin: 5%;
        font-family: 'Helvetica Neue-Medium';
        background-color: #179e17;
        border-radius: 23px;
        font-size: 23px;
        line-height: 28px;
        color: #ffffff;
        padding: 20px;
      }
    }
    .schedule_summary {
      padding: 4% 0% 2% 0%;
    }
  }
  .schedule_features {
    margin-bottom: 10%;

    .schedule_card {
      background-color: #e4eef5;
      border-radius: 28px;
      padding: 30px;

      h3 {
        padding: 10px;

        .dot {
          display: inline-block;
          margin-right: 10px;
          margin-bottom: 4px;
          border-radius: 50%;
          border-style: solid;
          border-width: 1px;
          border-color: black;
          background-color: black;
          height: 7px;
          width: 7px;
        }
      }
    }
  }
}
