@import "./_variables.scss";
@import "./components/datatable.scss";
@import "./client/dashboard.scss";
@import "./client/certificates.scss";
@import "./client/customers.scss";

.content {
  width: 85%;
  padding: 100px 20px;
  margin-left: 15%;
  // background-color: #F4F5F7;
}
// react datatable css
.rdt_TableHeader {
  ~ div {
    display: unset;
  }
}
.rdt_Table {
  .remove {
    cursor: pointer;
  }
  .edit {
    cursor: pointer;
  }
}
a {
  // color: $blue !important;
  color: $blue; // Should be changed

  &:hover {
    color: $blue;
  }
}

.message {
  color: green;
}

.validation-error {
  color: #F5333F;
  font-size: 12px;
}

.fontweight-bold {
  font-weight: bold;
}

.image-btn {
  padding: 7px 20px;
  font-size: 12px;
  margin-right: 10px;
  border: none;
  border-radius: 3px;
  box-shadow: 0px 3px 5px #9299b80d;

  img {
    margin-right: 8px;
  }
}

.white-btn {
  background-color: white;
  color: #5a5f7d;

  &:hover {
    background-color: #e2e4e5;
  }
}

.large.text-primary {
  color: $blue !important;
}

.blue-btn {
  background-color: $blue;
  color: white !important;
  text-decoration: none !important;

  &:hover {
    background-color: $blue-hover !important;
    color: white !important;
  }
}

.btn-primary {
  background-color: $blue !important;
  border-color: $blue !important;
  color: white !important;

  &:hover {
    background-color: $blue-hover !important;
    border-color: $blue-hover !important;
  }
}

.btn-error {
  background-color: $red !important;
  border-color: $red !important;
  color: white !important;

  &:hover {
    background-color: $red-hover !important;
    border-color: $red-hover !important;
  }
}

button:disabled {
  cursor: not-allowed;
}

// Client Customer page style
.customer-datatable-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

header {
  .form-control {
    background-color: #f4f5f7;
    border: none;
    outline: none;
    color: #adb4d2;
    font-size: 14px;
    padding: 8px 15px;
    border-radius: 30px;

    &:focus {
      box-shadow: none;
      background-color: #f4f5f7;
    }

    &::-webkit-input-placeholder {
      color: #adb4d2;
    }
  }
}

// Certificate page Style
.filter-span {
  .form-control {
    background-color: #f4f5f7;
    border: none;
    outline: none;
    color: #adb4d2;
    font-size: 14px;
    padding: 8px 15px;
    border-radius: 30px;
    // position: relative;
    
    &:focus {
      box-shadow: none;
      background-color: #f4f5f7;
    }

    &::-webkit-input-placeholder {
      color: #adb4d2;
    }
  }
}

.memo-wrapper {
  border: 1px solid #ced4da;
}

.rdw-editor-main {
  padding: 5px;
  background: white;
}
