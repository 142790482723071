@import "../variables";

.communication-content-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    .flex-item {
        display: flex;
        align-items: center;

        .image-part {
            height: 35px;
            width: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: white;
            border-radius: 3px;
        }

        &.date-range {
            box-shadow: 0px 3px 5px #9299B80D;
            border-radius: 3px;
            background: white;
            margin-right: 10px;
        }
    }

    .react-daterange-picker__calendar {
        top: 32px !important;
        left: -36px !important;
    }
    
    .react-daterange-picker__wrapper {
        border: none;
        color: #5A5F7D;

        .react-daterange-picker__inputGroup {
            font-size: 12px;

            input {
                &:focus {
                    box-shadow: none;
                }
            }
        }
    }
}
.communication-content {
    display: flex;

    .menu-list {
        max-width: 300px;
        width: 300px;
        padding: 18px;
        background-color: white;
        box-shadow: 0px 5px 20px #9299B808;
        border-radius: 10px;
        margin-right: 30px;
        flex: 1 0 auto;
    
        .menu-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 14px 10px;
            cursor: pointer;
    
            &.active {
                background-color: rgba($color: $blue, $alpha: 0.1);
                border-radius: 4px;
            }
    
            div {
                display: flex;
                align-items: center;
                justify-content: center;
    
                img {
                    margin-right: 19px;
                }
    
                span {
                    color: #5A5F7D;
                    font-size: 14px;
                }
            }
    
            .label {
                width: 20px;
                height: 20px;
                font-size: 11px;
                background-color: rgba($color: $blue, $alpha: 0.1);
                color: $blue;
                border-radius: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }


    .menu-content {
        background-color: white;
        box-shadow: 0px 5px 20px #9299B808;
        border-radius: 10px;
        padding: 17px 0 17px;
        width: 100%;
    }

    .communication-table-header {
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #F1F2F6;

        .flex-item {
            display: flex;
            align-items: center;

            input {
                margin-right: 30px;
            }

            .action-item {
                height: 38px;
                width: 38px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 5px;
                border-radius: 30px;
                background-color: rgba($color: $blue, $alpha: 0.05);
                cursor: pointer;
            }
        }

        .search-mail {
            position: relative;

            input {
                background-color: #F4F5F7;
                border: none;
                outline: none;
                color: #ADB4D2;
                font-size: 14px;
                padding: 8px 15px 8px 40px;
                border-radius: 30px;

                &:focus {
                    box-shadow: none;
                }

                &::-webkit-input-placeholder {
                    color: #ADB4D2;
                }
            }

            img {
                position: absolute;
                top: 12px;
                left: 13px;
            }
        }
    }
}

.image-btn {
    padding: 7px 20px;
    font-size: 12px;
    margin-right: 10px;
    border: none;
    border-radius: 3px;
    box-shadow: 0px 3px 5px #9299B80D;

    img {
        margin-right: 8px;
    }
}
