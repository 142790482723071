@import "../../../assets/style/variables";

.custom-control-label::before {
  border-radius: 10px;
}

tr:nth-child(2n + 1) {
  background-color: #aeaeae0a !important;
}

td {
  padding: 8px 10px;
  color: #4c4c51;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  font-family: 'Open Sans', sans-serif;
  padding: 12px 15px;
}

th {
  padding: 8px 10px;
  width: 50%;
  color: #4c4c51;
  font-weight: bold;
  font-size: 14px;
  padding: 13px 15px;
  line-height: 20px;
  font-family: 'Open Sans', sans-serif;
}

tr {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.profile-content {
  display: flex;

  .menu-list {
    max-width: 300px;
    width: 300px;
    padding: 18px;
    background-color: white;
    box-shadow: 0px 5px 20px #9299b808;
    border-radius: 10px;
    margin-right: 30px;
    flex: 1 0 auto;

    .menu-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 14px 10px;
      cursor: pointer;

      &.active {
        background-color: rgba($color: $blue, $alpha: 0.1);
        border-radius: 4px;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          margin-right: 19px;
        }

        span {
          color: #5a5f7d;
          font-size: 14px;
        }
      }

      .label {
        width: 20px;
        height: 20px;
        font-size: 11px;
        background-color: rgba($color: $blue, $alpha: 0.1);
        color: $blue;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .menu-content {
    background-color: white;
    box-shadow: 0px 5px 20px #9299b808;
    border-radius: 10px;
    padding: 17px;
    width: 100%;
  }
}

.form-control.profile-form-error {
  border-color: #F5333F;
}
