.authed {
  width: 100%;
  display: inline-flex;
  background-color: #f4f5f7;

  .authed-navbar {
    width: 85%;
    margin-left: 15%;
    padding: 20px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 2px 30px #9299b81a;
    background-color: white;
    position: fixed;
    top: 0;
    z-index: 2;

    .search {
      display: flex;
      align-items: center;
      width: 700px;
      img {
        margin-right: 10px;
      }
      .rbt {
        width: 100%;
      }
      input {
        border: none;
        outline: none;
        font-size: 14px;

        &:focus {
          box-shadow: none;
        }
      }
    }

    .right-side {
      display: flex;
      align-items: center;

      .nav-item {
        margin: 0 12.5px;
        cursor: pointer;
        position: relative;

        &.email {
          div {
            width: 5px;
            height: 5px;
            background-color: #20c997;
            position: absolute;
            top: -3px;
            left: 7px;
            border-radius: 10px;
          }
        }

        &.bell {
          div {
            width: 5px;
            height: 5px;
            background-color: #fa8b0c;
            position: absolute;
            top: -3px;
            left: 7px;
            border-radius: 10px;
          }
        }

        &.profile {
          img {
            max-width: 150px;
            max-height: 30px;
          }
        }
      }
    }
  }

  .authed-content {
    width: 100%;
  }

  .authed-footer {
    width: 85%;
    margin-left: 15%;
    padding: 20px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #9299b8;
    position: fixed;
    bottom: 0;
    background-color: white;
    box-shadow: 0px -5px 10px #9299b80d;
    z-index: 1;

    h6 {
      font-weight: 500;
      font-size: 14px;
    }

    div {
      display: flex;
      align-items: center;

      a {
        margin-left: 20px;
        color: #9299b8;
        font-size: 14px;
      }
    }
  }
}
