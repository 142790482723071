@import './_variables.scss';

.datatable-title {
  flex-wrap: wrap;
  font-size: 22px;
  color: rgba(0, 0, 0, 0.87);
  padding-left: 16px;
  padding-right: 8px;
}

.filter-span {
  position: relative;
  z-index: 999;
}

.rdt_TableHead {
  border-bottom: 1px solid #f1f2f6 !important;
  box-shadow: 0px 15px 40px #9299b833;

  .rdt_TableHeadRow {
    border: none !important;
    .rdt_TableCol_Sortable {
      color: $blue !important;
      text-align: center;
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
}

.rdt_TableRow {
  box-shadow: 0px 15px 40px #9299b833;
  border-bottom: 1px solid #f1f2f6 !important;
  padding: 20px 0;

  .rdt_TableCell {
    color: #272b41;
    font-size: 14px;
  }
}

.rdt_Pagination {
  border-top: none !important;
}

.send-email {
  font-size: 12px !important;
  float: right;
  margin-right: 23px;
}

.certificate-filters {
  width: 24% !important;
  display: inline !important;
}

.subHeaderComponent {
  width: 20% !important;
  margin-top: -50px !important;
}

.manual-notification {
  .rdt_TableHeader {
    display: none;
  }
}
