.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  .auth-inner {
    width: 850px;
    margin: auto;
    padding: 40px 55px 45px 55px;
    border-radius: 15px;
    transition: all 0.3s;

    &.login {
      width: 550px;
      margin: auto;
      padding: 40px 55px 45px 55px;
      border-radius: 15px;
      transition: all 0.3s;
    }
    
    .logo {
      text-align: center;
      border-bottom: 1px solid #ddd;
      margin-bottom: 10px;
      img {
        margin-bottom: 10px;
      }
    }
    .google {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .state {
      text-align: center;
      margin: 10px 0px;
      font-style: italic;
      font-size: 14px;
    }
    .or-seperator {
      margin: 20px 0 10px;
      text-align: center;
      border-top: 1px solid #ddd;
      i {
        padding: 0 10px;
        background: #fff;
        position: relative;
        top: -15px;
        z-index: 1;
      }
    }
    .link_group {
      text-align: center;
      padding: 10px 0px;
      p {
        margin-bottom: 5px;
      }
    }
    .form-control:focus {
      border-color: #167bff;
      box-shadow: none;
    }
    .error {
      color:#F5333F;
    }
  }
  h3 {
    text-align: center;
    margin: 0;
    line-height: 1;
    padding-bottom: 20px;
  }
}
