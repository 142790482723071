.plans_page {
  .plans {
    background-color: #1449aa;

    h1 {
      font-size: 52px;
      line-height: 62px;
      color: #ffffff;
    }

    h2 {
      color: #ffffff;
    }

    h3 {
      font-size: 33px;
      line-height: 39px;
      color: #ffffff;
      text-shadow: 0px 3px 6px #ffffffcb;
    }

    .limit {
      font-family: 'Helvetica Neue-Italic';
      font-size: 18px;
      line-height: 22px;
      color: #ffffff;
      text-shadow: 0px 3px 6px #ffffffcb;
    }

    .plans_summary {
      padding: 2% 5%;
      width: 70%;
      
      h5 {
        margin: 0;
        color: #ffffff;
        font-size: 19px;
        line-height: 23px;
      }
    }

    .plan_item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: #ffffff;
      border-right: 1px solid #1b6cce;

      &:hover {
        background-color: #1b6cce;
        label,
        span {
          color: #ffffff;
        }
      }

      &:first-child {
        border-right: 1px solid #1b6cce;
      }

      &:last-child {
        border: none;
      }

      .plan_item-title {
        text-transform: uppercase;
        font-size: 11px;
        line-height: 14px;
        padding-top: 30px;
      }

      .plan_item-price {
        padding: 25px;
        text-align: center;
        min-width: 220px;
        p {
          margin: 0;
          font-size: 12px;
          line-height: 20px;
        }
      }

      label {
        color: #00c4ff;
        font-size: 39px;
        line-height: 47px;
        text-decoration: line-through;
        margin: 10px 0px 5px 25px;
      }

      span {
        color: #386dd0;
        font-size: 14px;
        line-height: 17px;
      }

      h5 {
        font-size: 14px;
        line-height: 16px;
      }

      .details {
        font-family: 'Helvetica Neue-Medium';
        background-color: #0c2e6d;
        border: 2px solid #ffffff;
        border-radius: 27px;
        color: #ffffff;
        padding: 10px 25px;
        margin-bottom: 20px;
      }
    }
  }
}
